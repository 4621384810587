import React from 'react';

import { LinkD } from '../components/linkd';
import Seo from '../components/seo';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';


const PrivacyPage = () => (
  <>
    <Seo title="Privacy" />
    <Paper sx={{padding: '0.5em 0.4em 1em'}}>
      <Typography variant='h4' component='h1'>
        Privacy Policy
      </Typography>

    <Box mb={3}><h3>Who we are</h3>
  Werner Digital Technology, Inc. is a United States based business registered in the state of Indiana since 2002.
  For more information, please see our
  <LinkD to="/about"> About&nbsp;Us </LinkD>
      page.
    </Box>
    <Box mb={3}><h3>What data do we collect and why</h3>
      We collect and maintain visitor logs which include the vistor's IP address
      browser agent string.  If you open an account, we collect an email address
      for account security purposes.
    </Box>

    <Box mb={3}><h3>Cookie Use</h3>
      If you have an account and you log in to this site,
      we may set up multiple session cookies to save your login information.

      Login cookies may last for up to two days, but can be removed
      automatically after periods of inactivity.  If you log out
      of your account, or close your browser, the login cookies will be removed.
    </Box>
    <Box mb={3}><h3>Who we share your data with</h3>
        We do not share or sell your data to third parties.
    </Box>
    <Box mb={3}><h3>How long do we retain your data?</h3>
        Visitor logs are retained for 90 days. Login and account information
        is retained until 30 days after account closure.
    </Box>
    <Box mb={3}><h3>Rights you have over your data</h3>
        If you have an account on this site, you can request
        that we erase any account data we hold and close your
        account immediately, including any data you have provided
        to us. This does not include any data we are obliged to
        keep for administrative, legal, or security purposes.
    </Box>
    <Box mb={3}><h3>More information</h3>
        Please
    <LinkD to="/contact"> Contact&nbsp;Us </LinkD>
        for questions or more information about our website privacy policy.
    </Box>

    </Paper>
  </>
)

export default PrivacyPage
